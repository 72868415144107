import React from 'react';

import Tools from '../../assets/about-image.svg';

import { Container } from './styles';

const About: React.FC = () => {
  return (
    <Container id="about">
      <div data-aos="zoom-in" className="about-left" data-anime="left">
        <img src={Tools} alt="Tools Educacional" />
      </div>
      <div data-aos="fade-left" className="about-right" data-anime="right">
        <h1>Sobre</h1>
        <p>
          Somos uma empresa genuinamente brasileira, formada por jovens
          apaixonados por educação, que acreditam na tecnologia como recurso
          para potencializar as experiências de aprendizagem.
        </p>

        <p>
          A missão da Tools Educacional é transformar a educação brasileira,
          através da inovação tecnológica. Implantamos e configuramos o google
          workspace for education em instituições de ensino, capacitando
          docentes, dicentes e tecnicos da educação, com foco no desenvolvimento
          das competências digitais.
        </p>
      </div>
    </Container>
  );
};

export default About;
