import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 80px;
  max-width: 1213px;
  position: fixed;
  background: rgba(255, 255, 255, 1);
  z-index: 100;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .toggle {
    display: none;
  }

  .disabled {
    display: flex;
    align-items: center;
    visibility: visible;

    button {
      display: none;
    }

    li {
      list-style: none;
      margin-right: 36px;
      position: relative;

      &:nth-child(8) {
        position: relative;
      }

      a {
        text-decoration: none;
        color: var(--Brand-Title);
        font-size: 16px;
        font-weight: 600;
        font-family: 'poppins', sans-serif;
      }

      .isDisabled {
        color: currentColor;
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
      }
    }

    .active-menu {
      border-bottom: 2px solid var(--Brand-Blue);
      transition: 0.2s linear;
    }
  }

  .active {
    transform: translateX(280px);
    transition: 0.5s;
  }

  .close-menu {
    display: none;
  }

  @media (max-width: 1100px) {
    padding: 0 32px;

    .toggle {
      display: block;
      width: 40px;
      height: 40px;
      padding: 0 8px;
      border-radius: 4px;
      background: transparent;
      border: 2px solid var(--Brand-Title);

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .bar {
        width: 100%;
        height: 3px;
        border-radius: 8px;
        margin-bottom: 4px;

        &:nth-child(1) {
          background: var(--Brand-Blue);
        }
        &:nth-child(2) {
          background: var(--Brand-Red);
        }
        &:nth-child(3) {
          margin-bottom: 0;
          background: var(--Brand-Yellow);
        }
      }
    }

    .disabled {
      visibility: hidden;
      display: none;
    }

    .active {
      width: 280px;
      height: 100vh;
      bottom: 0;
      right: 0;
      background: #f0f0f0;
      box-shadow: 0px 0 8px #888;
      z-index: 100;

      display: flex;
      position: fixed;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translateX(0);
      transition: 0.5s;

      li {
        margin: 0 0 32px 0;
        list-style: none;

        a {
          text-decoration: none;
          color: var(--Brand-Title);
          font-size: 16px;
          font-weight: 600;
          font-family: 'poppins', sans-serif;
        }

        .isDisabled {
          color: currentColor;
          cursor: not-allowed;
          opacity: 0.5;
          text-decoration: none;
        }
      }

      button {
        width: 40px;
        height: 40px;
        border: none;
        position: absolute;
        top: 24px;
        right: 24px;
        border-radius: 20px;
        background: rgba(200, 200, 200, 0.3);

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          color: var(--Brand=Title);
        }

        &:hover {
          svg {
            transform: rotate(360deg);
            transition: 0.1s linear;
          }
        }
      }

      .active-menu {
        border-bottom: 2px solid var(--Brand-Blue);
        transition: 0.2s linear;
      }
    }
  }

  @media (max-width: 768px) {
    .active-bg {
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;

      z-index: 1;
    }

    .close-menu {
      display: none;
    }
  }
`;
