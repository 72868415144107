import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  max-width: 1213px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    position: relative;
    font-size: 48px;
    color: var(--Brand-Title);
    font-family: 'Poppins', sans-serif;

    &::before {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 2px;
      background: var(--Brand-Blue);

      position: absolute;
      top: 37px;
      left: 650px;
    }
  }

  .form-container {
    width: 100%;
    height: 560px;
    margin-top: 32px;

    display: flex;

    .form-left {
      height: inherit;
      padding: 32px;
      background: var(--Brand-Blue);
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;

      .infos {
        span {
          font-size: 22px;
          font-family: 'poppins', sans-serif;
          font-weight: bold;
          color: #ffffff;
        }

        p {
          font-size: 16px;
          font-family: 'montserrat', sans-serif;
          font-weight: 400;
          color: #ffffff;
          margin-top: 4px;
        }

        .contact-container {
          margin-top: 48px;

          .contact-group {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            svg {
              color: #ffffff;
              margin-right: 4px;
            }
          }
        }

        .socials {
          flex: 1;
          width: 100%;
          margin-top: 220px;

          display: flex;
          justify-content: center;

          a {
            margin-right: 24px;
            transition: 0.4s;

            &:hover {
              transform: translateY(-8px);
            }

            svg path {
              fill: var(--Brand-White);
            }
          }
        }
      }
    }

    .form-right {
      height: inherit;
      padding: 32px;
      background: rgba(204, 204, 204, 0.3);
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;

      span {
        font-size: 22px;
        font-family: 'poppins', sans-serif;
        font-weight: bold;
        color: var(--Brand-Title);
      }

      p {
        font-size: 16px;
        font-family: 'montserrat', sans-serif;
        font-weight: 400;
        color: var(--Brand-Title);
        margin-top: 4px;
      }

      form {
        max-width: 100%;
        display: grid;
        grid-template-areas:
          'name name'
          'email telefone'
          'message message'
          'send send';
        gap: 16px;

        margin-top: 32px;

        div {
          label {
            font-size: 1rem;
            font-weight: 600;
            font-family: 'poppins', sans-serif;
            color: var(--Brand-Title);
          }

          &:nth-child(1) {
            grid-area: name;
          }

          &:nth-child(2) {
            grid-area: email;
          }
          &:nth-child(3) {
            grid-area: telefone;
          }
          &:nth-child(4) {
            grid-area: message;

            display: flex;
            flex-direction: column;

            textarea {
              height: 90px;
              border: 2px solid var(--Brand-Text);
              padding: 8px 16px;
              border-radius: 8px;

              font-size: 14px;
              font-family: 'poppins', sans-serif;
            }
          }

          .error {
            font-size: 12px;
            color: crimson;

            display: flex;
            align-items: center;

            svg {
              margin-left: 4px;
            }
          }
        }

        .button {
          width: 100%;

          grid-area: send;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          button {
            width: 180px;
            height: 48px;
            padding: 0 4px;
            background: #246ff2;
            border-radius: 4px;
            border: none;

            font-size: 16px;
            font-family: 'poppins', sans-serif;
            color: var(--Brand-White);
            transition: 0.2s;
            cursor: pointer;

            &:hover {
              filter: brightness(0.8);
            }
          }
        }
      }
    }
  }

  @media (max-width: 1920px) {
    .form-left {
      width: 40%;
    }

    .form-right {
      width: 60%;
    }
  }

  @media (max-width: 1100px) {
    height: auto;
    padding: 0 32px;

    .form-container {
      height: auto;
      flex-direction: column;
      flex-flow: column-reverse;

      .form-left {
        width: 100%;

        border-top-left-radius: 0;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      .form-right {
        width: 100%;

        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  @media (max-width: 768px) {
    h1 {
      text-align: center;

      &::before {
        content: none;
      }
    }

    .form-container {
      .form-right {
        form {
          display: grid;
          grid-template-areas:
            'name '
            'email'
            'telefone'
            'message'
            'send';
          gap: 16px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    h1 {
      line-height: 120%;
      font-size: 32px;
      text-align: center;
      margin-top: 32px;

      &::before {
        content: none;
      }
    }
  }

  @media (max-width: 425px) {
    padding: 0 16px;

    .form-container {
      .form-left {
        height: 20%;
        padding: 16px;

        .infos {
          height: 50vh;
          display: flex;
          align-items: center;
          flex-direction: column;

          span {
            font-size: 18px;
          }

          p {
            text-align: center;
          }

          .socials {
            margin-top: 60px;

            a:nth-child(2) {
              margin-right: 0;
            }
          }
        }
      }

      .form-right {
        padding: 16px;

        form {
          div {
            width: inherit;
          }
        }
      }
    }

    .contact-container {
      .contact-group {
        justify-content: center;
        svg {
          width: 20px;
        }
      }
    }
  }
`;
