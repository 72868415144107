import React from 'react';

import IllustrationGoogleForEducation from '../../assets/help-illustration01.svg';
import IllustrationTrainingAndConsulting from '../../assets/help-illustration02.svg';
import IllustrationDeveloper from '../../assets/help-illustration03.svg';

import { Container } from './styles';

const HowWeHelp: React.FC = () => {
  return (
    <Container id="howwehelp">
      <h1>Como ajudamos</h1>
      <span>
        Conheça como ajudamos instituições a crescer com o apoio da tecnologia e
        de plataformas fantásticas.
      </span>

      <div className="How-We-Help-container">
        <div className="google-container">
          <div data-aos="fade-right" className="google-for-education">
            <h2>
              Google <br /> For Education
            </h2>
            <p className="google-for-education-text">
              Implementamos o google workspace for education em sua instituição.
              Ajudamos sua rede educacional a realizar uma transformação digital
              com foco na inovação, utilizando as melhores tecnologias do
              ecossistema Google.
            </p>
          </div>
          <div
            data-aos="fade-left"
            className="google-for-education-illustration"
          >
            <img
              src={IllustrationGoogleForEducation}
              alt="Google for education"
            />
          </div>
        </div>
        <div className="training-container">
          <div
            data-aos="fade-right"
            className="training-and-consulting-illustration"
          >
            <img
              src={IllustrationTrainingAndConsulting}
              alt="Training and consulting"
            />
          </div>

          <div data-aos="fade-left" className="training-and-consulting">
            <h2>Treinamentos e consultorias</h2>
            <p className="training-and-consulting-text">
              Treinamos seus professores para utilizarem o melhor da plataforma
              google workspace for education com
              <strong> foco na aprendizagem</strong>. Além do treinamento,
              oferecemos consultorias
              <strong> nas mais variadas tecnologias e plataformas </strong>
              mostrando o poder que as mesmas podem proporcionar para os
              docentes e alunos, quando aplicadas com significado.
            </p>
          </div>
        </div>

        <div className="developer-container">
          <div data-aos="fade-right" className="developer">
            <h2>Desenvolvimento de sistemas</h2>
            <p className="developer-text">
              Desenvolvemos sistemas para gerenciamento de gestão escolar,
              atomatizando processos e economizando recursos e tempo dos nossos
              gestores e professores. Todos os nossos sistemas são desenvolvidos
              utilizando as mais modernas tecnologias do mercado.
            </p>
          </div>
          <div data-aos="fade-left" className="developer-illustration">
            <img src={IllustrationDeveloper} alt="Developer" />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HowWeHelp;
