import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Layout from '../pages/LandingPage';
import NotFound from '../pages/Errors/NotFound';
import PageUnder from '../pages/Errors/PageUnderConstruction';
import Resources from '../components/Resources';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Layout} />
        <Route exact path="/courses" component={PageUnder} />
        <Route exact path="/blog" component={PageUnder} />
        <Route exact path="/resources" component={Resources} />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
