import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: var(--Brand-White);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 18px;
    color: var(--Brand-Title);
    font-weight: 500;
    font-family: 'montserrat', sans-serif;
    margin-bottom: 32px;
  }

  button {
    width: 180px;
    height: 48px;
    border-radius: 4px;
    border: 0;

    border: 2px solid var(--Brand-Blue);
    background: none;
    cursor: pointer;

    transition: 0.5s;

    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: bold;
      color: var(--Brand-Blue);
    }

    &:hover {
      transform: translateY(-8px);
      box-shadow: 0 8px 30px rgba(36, 111, 242, 0.5);
    }
  }

  @media (max-width: 768px) {
    padding: 0 16px;

    img {
      width: 50%;
      position: relative;
    }

    p {
      text-align: center;
      font-size: 16px;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 425px) {
    padding: 0 16px;

    img {
      width: 100%;
      position: relative;
    }

    p {
      text-align: center;
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
`;
