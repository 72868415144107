import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../../../assets/under.jpg';

import { Container } from './styles';

const PageUnderConstruction: React.FC = () => {
  return (
    <Container>
      <div className="title">
        <h1>Está página ainda está em construção!</h1>
        <div className="button-container">
          <button className="constrution">
            <Link to="/">Voltar para home</Link>
          </button>
        </div>
      </div>

      <div className="image-container">
        <img src={Image} alt="Under" />
      </div>
    </Container>
  );
};

export default PageUnderConstruction;
