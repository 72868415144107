import styled, { keyframes } from 'styled-components';

const Bullet01 = keyframes`
  0% {
    opacity: 0.3;
  } 50% {
    opacity: 1;
  } 100% {
    opacity: 0.3;
  }
`;
const Bullet02 = keyframes`
   0% {
    opacity: 0.3;
  } 50% {
    opacity: 1;
  } 100% {
    opacity: 0.3;
  }
`;
const Bullet03 = keyframes`
   0% {
    opacity: 0.3;
  } 50% {
    opacity: 1;
  } 100% {
    opacity: 0.3;
  }
`;

export const Container = styled.section`
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  div.bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);

    :nth-child(1),
    :nth-child(2) {
      margin-right: 6px;
    }

    :nth-child(1) {
      animation: ${Bullet01} linear infinite;
      animation-duration: 1.5s;
      animation-delay: 0.2s;
    }
    :nth-child(2) {
      animation: ${Bullet02} linear infinite;
      animation-duration: 1.5s;
      animation-delay: 0.4s;
    }
    :nth-child(3) {
      animation: ${Bullet03} linear infinite;
      animation-duration: 1.5s;
      animation-delay: 0.6s;
    }
  }

  @media (max-width: 768px) {
    div.bullet {
      width: 10px;
      height: 10px;
      max-width: 10px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
`;
