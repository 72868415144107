import Routes from './Routes/routes';

import { ToastContainer } from 'react-toastify';

import GlobalStyles from './styles/GlobalStyles';

function App() {
  return (
    <>
      <Routes />
      <ToastContainer />
      <GlobalStyles />
    </>
  );
}

export default App;
