import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import Logo from '../../assets/Logo.svg';

import { Container } from './styles';

const Header: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [item, setItems] = useState({
    home: true,
    howwehelp: false,
    about: false,
    team: false,
    blog: false,
    contact: false,
  });

  const menuActive = (menu: string) => {
    switch (menu) {
      case 'home':
        setItems({
          ...item,
          home: true,
          howwehelp: false,
          about: false,
          team: false,
          blog: false,
          contact: false,
        });

        break;
      case 'howwehelp':
        setItems({
          ...item,
          home: false,
          howwehelp: true,
          about: false,
          team: false,
          blog: false,
          contact: false,
        });

        break;
      case 'about':
        setItems({
          ...item,
          home: false,
          howwehelp: false,
          about: true,
          team: false,
          blog: false,
          contact: false,
        });

        break;
      case 'team':
        setItems({
          ...item,
          home: false,
          howwehelp: false,
          about: false,
          team: true,
          blog: false,
          contact: false,
        });

        break;
      case 'blog':
        setItems({
          ...item,
          home: false,
          howwehelp: false,
          about: false,
          team: false,
          blog: true,
          contact: false,
        });

        break;
      case 'contact':
        setItems({
          ...item,
          home: false,
          howwehelp: false,
          about: false,
          team: false,
          blog: false,
          contact: true,
        });

        break;

      default:
        break;
    }
  };

  const pathname = window.location.pathname;

  return (
    <Container>
      <div className="logo">
        <img src={Logo} alt="Tools Educacional" />
      </div>

      <button className="toggle" onClick={() => setShowMenu(!showMenu)}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>

      <ul className={showMenu ? 'active' : 'disabled'}>
        <button onClick={() => setShowMenu(false)}>
          <FiX size={24} />
        </button>
        <li className={item.home ? 'active-menu' : ''}>
          <a
            href={pathname === '/resources' ? '/' : '#home'}
            onClick={() => menuActive('home')}
          >
            Home
          </a>
        </li>
        <li className={item.howwehelp ? 'active-menu' : ''}>
          <a
            href={pathname === '/resources' ? '/#howwehelp' : '#howwehelp'}
            onClick={() => menuActive('howwehelp')}
          >
            Como Ajudamos
          </a>
        </li>
        <li className={item.about ? 'active-menu' : ''}>
          <a
            href={pathname === '/resources' ? '/#about' : '#about'}
            onClick={() => menuActive('about')}
          >
            Sobre
          </a>
        </li>
        <li className={item.team ? 'active-menu' : ''}>
          <a
            href={pathname === '/resources' ? '/#team' : '#team'}
            onClick={() => menuActive('team')}
          >
            Time
          </a>
        </li>

        <li>
          <Link to="#" className="isDisabled">
            Blog
          </Link>
        </li>
        <li>
          <Link to="#" className="isDisabled">
            Cursos
          </Link>
        </li>
        <li>
          <Link to="#" className="isDisabled">
            Eventos
          </Link>
        </li>
        <li className={item.contact ? 'active-menu' : ''}>
          <a
            href={pathname === '/resources' ? '/#contact' : '#contact'}
            onClick={() => menuActive('contact')}
          >
            Contato
          </a>
        </li>

        <li>
          <Link to="/resources">Recursos</Link>
        </li>
      </ul>

      <div
        className={showMenu === false ? 'close-menu' : 'active-bg'}
        onClick={() => setShowMenu(false)}
      ></div>
    </Container>
  );
};

export default Header;
