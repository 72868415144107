import React, { useEffect } from 'react';
import aos from 'aos';

import Header from '../../components/Header';
import Home from '../../components/Home';
import HowWeHelp from '../../components/HowWeHelp';
import About from '../../components/About';
import Team from '../../components/Team';
import Contact from '../../components/Contact';
import Footer from '../../components/Footer';

import { Container } from './styles';

const LandingPage: React.FC = () => {
  useEffect(() => {
    aos.init({
      duration: 2000,
    });
  }, []);

  return (
    <Container>
      <Header />
      <Home />
      <HowWeHelp />
      <About />
      <Team />
      <Contact />
      <Footer />
    </Container>
  );
};

export default LandingPage;
