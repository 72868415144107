import React, { InputHTMLAttributes, useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { IconBaseProps } from 'react-icons';
//import { FiAlertTriangle } from 'react-icons/fi';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const Input: React.FC<InputProps> = ({ name, icon: Icon, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container isErrored={!!error}>
        <input ref={inputRef} {...rest} />
        {/* {error && (
          <ErrorIcon>
            <FiAlertTriangle color="#e02041" size={20} />
          </ErrorIcon>
        )} */}
      </Container>
      {/* {error && <Error title={error} />} */}
    </>
  );
};

export default Input;
