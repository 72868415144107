import React, { useCallback, useRef, useState } from 'react';
import { MdEmail, MdMap } from 'react-icons/md';
import { FiAlertTriangle } from 'react-icons/fi';
import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../utils/getValidationErrors';

//import { ReactComponent as Facebook } from '../../assets/facebook-icon.svg';
import { ReactComponent as Instagram } from '../../assets/instagram-icon.svg';
import { ReactComponent as Linkedin } from '../../assets/linkedin-icon.svg';

import Input from '../../components/Input';
import Loading from '../../components/Loading';

import api from '../../services/api';

import { Container } from './styles';

interface FormAttributes {
  name: string;
  phone: number;
  email: string;
  message: string;
}

interface ErrosProps {
  name?: string;
  tel?: string;
  email?: string;
  message?: string;
}

const Contact: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errors, setErrors] = useState({} as ErrosProps);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: FormAttributes) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido!')
            .test('tel', 'Informe e-mail ou telefone!', function () {
              if (phone === '' && email === '') {
                setEmailError(true);
                return false;
              } else {
                setEmailError(false);
                return true;
              }
            }),
          tel: Yup.string()
            .matches(/(\(?\d{2}\)?\s)?(\d{4,5}?-?\d{4})/, 'Telefone invalido!')
            .min(11, 'No mínimo 11 digitos e ser um número válido!')
            .max(14, 'No máximo 14 digitos e ser um número válido!')
            .test('tel', 'Informe telefone ou e-mail!', function () {
              if (phone === '' && email === '') {
                setPhoneError(true);
                return false;
              } else {
                setPhoneError(false);
                return true;
              }
            }),

          message: Yup.string().test(
            'message',
            'Mensagem Obrigatória',
            function () {
              if (message === '') {
                setMessageError(true);
                return false;
              } else {
                setMessageError(false);
                return true;
              }
            }
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        const response = await api.post('/contact', {
          name: data.name,
          email: email,
          phone: phone,
          message: message,
        });

        if (response.status === 201) {
          if (formRef.current !== null) {
            formRef.current.clearField('name');
            formRef.current.clearField('email');
            formRef.current.clearField('tel');

            setEmail('');
            setPhone('');
            setMessage('');
            setEmailError(!emailError);
            setPhoneError(!phoneError);
            setMessageError(false);
            setLoading(false);

            setErrors({});
          }

          toast.success('Contato enviado com sucesso!');
        } else {
          toast.error('Ocorreu um erro ao entrar em contato!');
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          setErrors(errors);

          return;
        }
      }
    },
    [message, phone, email, emailError, phoneError]
  );

  return (
    <Container id="contact">
      <h1>Entre em contato conosco</h1>

      <div
        data-aos="fade-up"
        data-aos-duration="2000"
        className="form-container"
      >
        <div className="form-left">
          <div className="infos">
            <span>Informações de contato</span>
            <p>Para mais informações acesse nossos meios de contato.</p>

            <div className="contact-container">
              <div className="contact-group">
                <MdEmail size={28} />
                <p>contato@toolseducacional.com.br</p>
              </div>
              {/* <div className="contact-group">
                <MdPhone size={28} />
                <p>+55 88 3563-2243</p>
              </div> */}
              <div className="contact-group">
                <MdMap size={28} />
                <p>Icó - Ce</p>
              </div>
            </div>

            <div className="socials">
              {/* <a href="https://">
                <Facebook />
              </a> */}
              <a
                href="https://www.instagram.com/toolseducacional/"
                target="__blank"
              >
                <Instagram />
              </a>
              <a
                href="https://www.linkedin.com/company/toolseducacional"
                target="__blank"
              >
                <Linkedin />
              </a>
            </div>
          </div>
        </div>
        <div className="form-right">
          <span> Se preferir envie uma mensagem.</span>
          <p>Preencha os campos abaixo para enviar uma mensagem para nós.</p>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <div>
              <label>Nome</label>
              <Input name="name" type="text" placeholder="Seu nome" />
              {errors.name && (
                <p className="error">
                  {errors.name} <FiAlertTriangle size={14} />
                </p>
              )}
            </div>

            <div>
              <label>E-mail</label>
              <Input
                name="email"
                type="text"
                placeholder="Seu e-mail"
                onChange={(e) => setEmail(e.target.value)}
                style={
                  emailError
                    ? { borderColor: '#e02041' }
                    : { borderColor: 'var(--Brand-Text)' }
                }
              />
              {errors.email && (
                <p className="error">
                  {errors.email} <FiAlertTriangle size={14} />
                </p>
              )}
            </div>
            <div>
              <label>Telefone</label>
              <Input
                name="tel"
                type="tel"
                placeholder="Seu telefone"
                onChange={(e) => setPhone(e.target.value)}
                style={
                  phoneError
                    ? { borderColor: '#e02041' }
                    : { borderColor: 'var(--Brand-Text)' }
                }
              />
              {errors.tel && (
                <p className="error">
                  {errors.tel}
                  <FiAlertTriangle size={12} />{' '}
                </p>
              )}
            </div>
            <div>
              <label>Mensagem</label>
              <textarea
                name="message"
                placeholder="Sua mensagem..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={
                  messageError
                    ? { borderColor: '#e02041' }
                    : { borderColor: 'var(--Brand-Text)' }
                }
              />
              {errors.message && (
                <p className="error">
                  {errors.message} <FiAlertTriangle size={14} />{' '}
                </p>
              )}
            </div>
            <div className="button">
              <button type="submit">
                {loading ? <Loading /> : 'Enviar mensagem'}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
