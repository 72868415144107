import React from 'react';
import { FiFileText, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { ReactComponent as Instagram } from '../../assets/instagram-icon.svg';
import { ReactComponent as Linkedin } from '../../assets/linkedin-icon.svg';

import Rangel from '../../assets/rangel.svg';
import Paulo from '../../assets/paulo.svg';
import Adriano from '../../assets/adriano.svg';
import Massaro from '../../assets/massaro.svg';

import { Container } from './styles';

const Team: React.FC = () => {
  const moveTeam = (action: string) => {
    let container = document.getElementsByClassName('team-cards');

    if (action === 'next') {
      container[0].scrollTo({ left: 200, behavior: 'smooth' });
    } else if (action === 'prev') {
      container[0].scrollTo({ left: 0, behavior: 'smooth' });
    }
  };
  return (
    <Container id="team">
      <h1>Time</h1>
      <span>Conheça o time que vai ajudar sua instituição.</span>

      <button className="prev" onClick={() => moveTeam('prev')}>
        <FiChevronLeft size={24} />
      </button>
      <div className="team-cards">
        <div className="card-wrapper">
          <div className="card">
            <div className="photo-container">
              <img src={Rangel} alt="Rangel Félix" />
            </div>
            <strong>Rangel Félix</strong>
            <span>CEO, Fundador</span>

            <p>
              É Especialista em Docência do Ensino Superior e Tecnólogo em Redes de Computadores (IFCE), 
              possui também <b>Google Certified Educator</b>. Apaixonado por educação e 
              tecnologia, desenvolve consultorias e palestras com o objetivo de oportunizar transformações 
              digitais e aprendizagem destrutiva mediada por tecnologias.
            </p>

            <div />
            <div className="social">
              <a
                href="https://www.instagram.com/profrangelfelix/"
                target="__blank"
              >
                <Instagram />
              </a>
              <a
                href="https://www.linkedin.com/in/prof-rangel-f%C3%A9lix-6a07451a4/"
                target="__blank"
              >
                <Linkedin />
              </a>

              <a href="http://lattes.cnpq.br/0046108680536603" target="__blank">
                <FiFileText size={24} color="#246FF2" />
              </a>
            </div>
          </div>
          <div className="card">
            <div className="photo-container">
              <img src={Paulo} alt="Paulo Cesar" />
            </div>
            <strong>Paulo Cesar</strong>
            <span>CTO</span>
            <p>
            É Graduando em Sistemas de Informação (IFCE), com intercâmbio em Engenharia Informática pela 
            Universidade de Evorá - Portugal. Possui experiência em desenvolvimento de sistemas 
            multi-plataformas, sendo também um amante das tecnologias Google.
            </p>

            <div />
            <div className="social">
              <a
                href="https://www.instagram.com/xpaulocesarx/"
                target="__blank"
              >
                <Instagram />
              </a>
              <a
                href="https://www.linkedin.com/in/paulo-cesar-henrique-da-silva-a3aa41140/"
                target="__blank"
              >
                <Linkedin />
              </a>
              <a href="http://lattes.cnpq.br/4732344200699016" target="__blank">
                <FiFileText size={24} color="#246FF2" />
              </a>
            </div>
          </div>
          <div className="card">
            <div className="photo-container">
              <img src={Adriano} alt="Adriano Maia" />
            </div>
            <strong>Adriano Maia</strong>
            <span>Designer Manager</span>
            <p>
              É Graduado em Redes de Computadores (IFCE) e Mestrando em Ciência da Computação (UFC).
              Possui também <b>Google Certified Educator</b>. Atua como designer a mais de dez anos, 
              sendo entusiastas de softwares livres e professor da área de tecnologia.
            </p>

            <div />
            <div className="social">
              <a href="https://www.instagram.com/adhryan/" target="__blank">
                <Instagram />
              </a>
              <a
                href=" https://www.linkedin.com/in/adriano-de-oliveira-maia-591829101"
                target="__blank"
              >
                <Linkedin />
              </a>
              <a href="http://lattes.cnpq.br/3539670050614663" target="__blank">
                <FiFileText size={24} color="#246FF2" />
              </a>
            </div>
          </div>
          <div className="card">
            <div className="photo-container">
              <img src={Massaro} alt="Massaro" />
            </div>
            <strong>Massaro Victor</strong>
            <span>Training Manager</span>
            <p>
              É Graduado em Redes de Computadores (IFCE), com especialização em Segurança da Informação (UNIVS). 
              Possui também <b>Google Certified Educator</b>. Atualmente, exerce o papel de professor da área de 
              tecnologia, desenvolvendo projetos educacionais que oportunizam inovações tecnológicas.
            </p>

            <div className="social">
              <a
                href="https://www.instagram.com/massarovictor/"
                target="__blank"
              >
                <Instagram />
              </a>
              <a
                href="https://www.linkedin.com/in/massaro-victor-pinheiro-alves-7398b2206/"
                target="__blank"
              >
                <Linkedin />
              </a>

              <a href="http://lattes.cnpq.br/6115713723883074" target="__blank">
                <FiFileText size={24} color="#246FF2" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <button className="next" onClick={() => moveTeam('next')}>
        <FiChevronRight size={24} />
      </button>
    </Container>
  );
};

export default Team;
