import styled from 'styled-components';

// const animateGoogleForEducation = keyframes`
//   0% {
//     opacity: 0;
//     transform: translateX(300px);
//   }
//   50% {
//     opacity: 0.5;
//     transform: translateX(100px);
//   }
//   110% {
//     opacity: 1;
//     transform: translateX(0);
//   }
// `;

export const Container = styled.section`
  width: 100%;
  max-width: 1213px;
  height: auto;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;

  h1 {
    position: relative;
    font-size: 48px;
    color: var(--Brand-Title);
    font-family: 'Poppins', sans-serif;

    &::before {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 2px;
      background: var(--Brand-Blue);

      position: absolute;
      top: 37px;
      right: -20px;
    }
  }

  span {
    font-size: 18px;
    font-family: 'Montserrant', sans-serif;
    color: rgba(77, 77, 77, 0.5);

    text-align: center;
    max-width: 500px;
    margin-bottom: 80px;
  }

  .How-We-Help-container {
    width: 100%;
    height: auto;

    [data-animate] {
      opacity: 0;
      transition: 0.3s;
    }

    [data-animate='left'] {
      transform: translate3d(-50px, 0, 0);
    }

    [data-animate='right'] {
      transform: translate3d(50px, 0, 0);
    }

    [data-animate].animate {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }

    .google-container {
      width: 100%;
      max-width: 1213px;

      display: flex;
      justify-content: center;

      .google-for-education {
        width: 50%;

        display: flex;
        align-items: center;
        flex-direction: column;

        h2 {
          font-size: 3rem;
          font-family: 'Poppins', sans-serif;
          line-height: 120%;
          color: var(--Brand-Blue);
        }

        .google-for-education-text {
          font-size: 1rem;
          font-family: 'Montserrant', sans-serif;
          margin-top: 16px;
          color: rgba(77, 77, 77, 0.5);
          max-width: 380px;
          line-height: 160%;
          margin-left: 42px;
        }
      }

      .google-for-education-illustration {
        width: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .training-container {
      width: 100%;
      max-width: 1213px;

      display: flex;
      justify-content: center;

      .training-and-consulting {
        width: 50%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        h2 {
          font-size: 3rem;
          font-family: 'Poppins', sans-serif;
          line-height: 120%;
          color: var(--Brand-Red);
          max-width: 460px;
        }

        .training-and-consulting-text {
          font-size: 1rem;
          font-family: 'Montserrant', sans-serif;
          margin-top: 16px;
          color: rgba(77, 77, 77, 0.5);
          max-width: 460px;
          line-height: 160%;
        }
      }

      .training-and-consulting-illustration {
        width: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .developer-container {
      width: 100%;
      max-width: 1213px;

      display: flex;
      justify-content: center;

      .developer {
        width: 50%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        h2 {
          font-size: 3rem;
          font-family: 'Poppins', sans-serif;
          line-height: 120%;
          color: var(--Brand-Yellow);
          max-width: 460px;
        }

        .developer-text {
          font-size: 1rem;
          font-family: 'Montserrant', sans-serif;
          margin-top: 16px;
          color: rgba(77, 77, 77, 0.5);
          max-width: 460px;
          line-height: 160%;
        }
      }

      .developer-illustration {
        width: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media (max-width: 960px) {
      .google-container {
        flex-direction: column;

        .google-for-education {
          width: 100%;
        }

        .google-for-education-illustration {
          width: 100%;
          margin-top: 48px;
        }
      }

      .training-container {
        flex-direction: column;
        flex-flow: column-reverse;

        .training-and-consulting {
          width: 100%;
        }
        .training-and-consulting-illustration {
          width: 100%;
          margin-top: 48px;
        }
      }

      .developer-container {
        flex-direction: column;

        .developer {
          width: 100%;
        }

        .developer-illustration {
          width: 100%;

          margin-top: 48px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 8px;

    h1 {
      margin-top: 48px;

      &::before {
        content: none;
      }
    }

    .training-container {
      .training-and-consulting,
      .developer {
        h2 {
          text-align: center;
        }

        .training-and-consulting-text {
          max-width: 360px !important;
        }
      }
    }
  }

  @media (max-width: 425px) {
    padding: 0 32px;

    h1 {
      font-size: 32px;
      margin-top: 48px;
      text-align: center;
      line-height: 120%;
    }

    span {
      margin-top: 16px;
    }

    .How-We-Help-container {
      .google-container {
        .google-for-education {
          h2 {
            font-size: 36px;
            text-align: center;
          }

          .google-for-education-text {
            margin-left: 0;
            text-align: center;
          }
        }
      }

      .training-container {
        .training-and-consulting {
          h2 {
            font-size: 36px;
            text-align: center;
          }

          .training-and-consulting-text {
            text-align: center;
          }
        }
      }

      .developer-container {
        .developer {
          h2 {
            font-size: 36px;
            text-align: center;
          }

          .developer-text {
            text-align: center;
          }
        }
      }
    }

    .google-for-education-illustration,
    .training-and-consulting-illustration,
    .developer-illustration {
      img {
        width: 100%;
      }
    }
  }
`;
