import React from 'react';

import Illustration from '../../assets/illustration-home.svg';

import { Container } from './styles';

const Home: React.FC = () => {
  return (
    <Container id="home">
      <div className="home-left">
        <h1>Aprendizagem e tecnologia.</h1>
        <p>
          Transformando experiências <br /> de aprendizagem mediadas por
          Tecnologias.
        </p>
        <button className="explore">
          <a href="#howwehelp">Conhecer</a>
        </button>
      </div>
      <div className="home-right">
        <img src={Illustration} alt="Illustration" />
      </div>
    </Container>
  );
};

export default Home;
