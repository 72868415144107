import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  max-width: 1213px;
  height: 100vh;

  display: flex;
  align-items: center;

  .home-left {
    width: 50%;

    h1 {
      font-size: 84px;
      font-family: 'Roboto', sans-serif;
      font-weight: 900;
      color: #202024;
    }

    p {
      font-size: 22px;
      font-family: 'montserrat', sans-serif;
      color: #a8a8b3;
      margin-top: 24px;
    }

    .explore {
      width: 208px;
      height: 48px;
      border-radius: 50px;
      border: 2px solid var(--Brand-Blue);
      margin-top: 36px;
      cursor: pointer;
      background: none;
      transition: 0.5s;

      a {
        text-decoration: none;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Roboto', sans-serif;
        color: var(--Brand-Blue);
      }

      &:hover {
        background: var(--Brand-Blue);

        box-shadow: 0 16px 40px rgba(36, 111, 242, 0.5);
        transform: translateY(-10px);
        transition: 0.5s;
      }

      &:hover a {
        color: var(--Brand-White);
      }
    }
  }

  .home-right {
    width: 50%;

    img {
      width: 100%;
    }
  }
  @media (max-width: 1920px) {
    max-height: 900px;
  }

  @media (max-width: 1100px) {
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .home-left,
    .home-right {
      width: 100%;
      height: 50%;
    }

    .home-left {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .home-right {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      img {
        width: 80%;
      }
    }
  }

  @media (max-width: 768px) {
    .home-left {
      h1 {
        margin-top: 120px;
        font-size: 64px;
      }

      p {
        font-size: 20px;
      }
    }

    .home-right {
      img {
        margin-top: 48px;
      }
    }
  }

  @media (max-width: 425px) {
    padding: 0 32px;

    .home-left {
      h1 {
        margin-top: 120px;
        font-size: 48px;
      }

      p {
        font-size: 20px;
      }
    }

    .home-right {
      img {
        margin-top: 48px;
      }
    }
  }
`;
