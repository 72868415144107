import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: inherit;
  height: 50px;
  color: #666;
  border-radius: 7px;
  background: #ffffff;
  border: 2px solid var(--Brand-Text);
  outline: 0;
  padding: 0 16px;
  font-size: 16px;
  font-family: 'poppins', sans-serif;

  &::placeholder {
    color: rgba(57, 57, 59, 0.25);
  }

  &:disabled {
    background: #eee;
  }

  display: flex;
  align-items: center;

  & + div {
    margin-top: 16px;
  }

  input {
    height: 100%;
    flex: 1;
    border: 0;
    color: #3d3d3d;
    background: #ffffff !important;
    outline: none;

    &::placeholder {
      color: #7d7d7d;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
    }
  }

  ${(props) =>
    props.isErrored &&
    css`
      & + div {
        margin-top: 0;
      }

      border-color: #e02041;
    `}
`;
