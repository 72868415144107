import React from 'react';

import Completed from '../../assets/completed.gif';
import Courses from '../../assets/courses.gif';
import Images from '../../assets/images.gif';
import Blog from '../../assets/blog.gif';

import Footer from '../../components/Footer';

import Logo from '../../assets/Logo.svg';

import { Container } from './styles';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

const Resources: React.FC = () => {
  return (
    <Container>
      <header>
        <div className="logo">
          <img src={Logo} alt="Tools Educacional" />
        </div>
        <div className="goBack">
          <FiArrowLeft size={20} />
          <Link to="/">Home</Link>
        </div>
      </header>
      <div className="resources-container">
        <h1>Novos recursos em desenvolvimento</h1>

        <div className="modules-container">
          <div className="blog-container">
            <div data-aos="fade-right" className="image-blog">
              <img src={Blog} alt="blog" />
            </div>
            <div data-aos="fade-left" className="content">
              <h2>Blog</h2>
              <p>Este é o módulo onde irá conter nossas publicações.</p>
            </div>
          </div>

          <div className="courses-container">
            <div data-aos="fade-right" className="content">
              <h2>Módulo de cursos</h2>
              <p>
                Este é o módulo onde os nossos alunos podem acessar os cursos
                desenvolvidos pela Tools Educacional.
              </p>
            </div>

            <div data-aos="fade-left" className="image-courses">
              <img src={Courses} alt="validate" />
            </div>
          </div>

          <div className="validate-container">
            <div data-aos="fade-right" className="image-validate">
              <img src={Completed} alt="validate" />
            </div>
            <div data-aos="fade-left" className="content">
              <h2>Validação de certificado</h2>
              <p>
                Este é o módulo onde os nossos alunos podem fazer o processo de
                validação de seus certificados.
              </p>
            </div>
          </div>

          <div className="images-container">
            <div data-aos="fade-right" className="content">
              <h2>Eventos</h2>
              <p>
                Este é o módulo onde irá conter todos os acontecimentos que o
                Tools Educacional proporcionou para as instituições e servidores
                treinados.
              </p>
            </div>
            <div data-aos="fade-left" className="image-images">
              <img src={Images} alt="images" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
};

export default Resources;
