import styled, { keyframes } from 'styled-components';

const TextAnimate = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ImageAnimate = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: rgb(246, 246, 246);
  margin: 0 auto;

  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;

  img {
    max-width: 30%;
    animation: ${ImageAnimate} 1s linear;
  }

  .title {
    width: 30%;
    height: inherit;
    animation: ${TextAnimate} 1s linear;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    h1 {
      max-width: 580px;
      font-size: 54px;
      font-weight: bold;
      line-height: 120%;
      color: var(--Brand-Title);
      font-family: 'poppins', sans-serif;
    }

    .button-container {
      width: inherit;
      display: flex;
      justify-content: flex-start;
    }

    button {
      width: 180px;
      height: 48px;
      border-radius: 4px;
      background: var(--Brand-Yellow);
      border: none;

      margin-top: 32px;
      cursor: pointer;
      transition: 0.3s;

      a {
        font-size: 16px;
        font-weight: 500;
        font-family: 'poppins', sans-serif;
        color: var(--Brand-Title);
        text-decoration: none;
      }

      &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 30px rgba(255, 204, 0, 0.8);
      }
    }
  }

  .image-container {
    width: 40%;
    height: inherit;

    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      min-width: 50%;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    flex-flow: column-reverse;

    align-items: center;

    img {
      max-width: 80%;
    }

    .title {
      width: 100%;

      align-items: center;
      justify-content: flex-start;

      h1 {
        text-align: center;
        margin-top: 16px;
      }

      .button-container {
        width: inherit;
        display: flex;
        justify-content: center;
      }
    }

    .image-container {
      height: 50%;
    }
  }
`;
