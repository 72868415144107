import styled, { keyframes } from 'styled-components';

const arrowAnimate = keyframes`
  0% {
    transform: translateX(-3px);
  }
  50% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(-3px);
  }
`;

export const Container = styled.section`
  width: 100%;
  max-width: 1213px;
  height: auto;
  position: relative;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    position: relative;
    font-size: 48px;
    color: var(--Brand-Title);
    font-family: 'Poppins', sans-serif;

    &::before {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 2px;
      background: var(--Brand-Yellow);

      position: absolute;
      top: 37px;
      left: 128px;
    }
  }

  span {
    font-size: 18px;
    text-align: justify;
    line-height: 142%;
    font-weight: 500;
    font-family: 'montserrat', sans-serif;
    color: rgba(77, 77, 77, 0.5);
    margin-top: 8px;
  }

  .team-cards {
    width: 100%;
    height: inherit;
    margin-top: 24px;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 8px;
    }

    .card-wrapper {
      width: 100%;
      height: 100%;
      min-height: 540px;
      display: flex;

      display: grid;
      grid-template-columns: 320px 320px 320px 320px 320px;
      gap: 32px;
      justify-content: space-between;
    }

    .card {
      width: 320px;
      height: 480px;
      background: #fafafa;
      padding: 0 24px;
      border-radius: 8px;
      border: 2px solid #ededed;
      box-shadow: 0px 48px 32px -32px rgba(0, 0, 0, 0.2),
        inset 0px 0px 24px #e5e5e5;
      position: relative;

      display: flex;
      align-items: center;
      flex-direction: column;

      &:hover {
        transform: scale(1.01);
        transition: 0.3s;
      }

      .photo-container {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        margin: 16px 0 8px 0;
        border: 4px solid var(--Brand-Blue);

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 120px;
          border-radius: 50%;
        }
      }

      strong {
        font-size: 18px;
        font-family: 'poppins', sans-serif;
        font-weight: bold;
        color: var(--Brand-Title);
      }

      span {
        font-size: 16px;
        font-family: 'poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        color: var(--Brand-Title);
        line-height: 80%;
      }

      p {
        font-size: 12px;
        font-family: 'montserrat', sans-serif;
        font-weight: 500;
        margin-top: 16px;
        color: var(--Brand-Title);
        text-align: center;
        line-height: 150%;
      }
    }

    .social {
      width: 85%;
      height: 40px;
      margin-top: 24px;
      padding: 32px 0;
      border-top: 1px solid rgba(204, 204, 204, 0.5);
      position: absolute;
      bottom: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin: 32px 8px;

        &:nth-child(3) {
          margin: 0;
        }
      }
    }
  }

  .prev,
  .next {
    width: 42px;
    height: 42px;
    background: var(--Brand-Blue);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: var(--Brand-White);
    }

    &:hover svg {
      animation: ${arrowAnimate} 2s linear infinite;
    }
  }

  .prev {
    top: 50%;
    left: -64px;
  }

  .next {
    top: 50%;
    right: -64px;
  }

  @media (max-width: 1920px) {
    max-height: 800px;
    position: relative;
  }

  @media (max-width: 1440px) {
    .team-cards {
      height: 100%;
      min-height: 550px;
    }
  }

  @media (max-width: 1100px) {
    height: auto;
    padding: 32px;
    align-items: center;

    .team-cards {
      width: 100%;

      .card-wrapper {
        height: auto;
        grid-template-columns: 320px 320px;
        justify-content: center;
        column-gap: 48px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 32px;
    max-height: fit-content;

    h1 {
      text-align: center;
    }

    .prev,
    .next {
      display: none;
    }

    .team-cards {
      width: 100%;
      height: inherit;

      .card-wrapper {
        grid-template-columns: 320px 320px;
      }
    }

    .actions {
      display: none;
    }
  }

  @media (max-width: 425px) {
    padding: 16px;
    height: inherit;

    h1 {
      font-size: 32px;
      text-align: center;

      &::before {
        content: none;
      }
    }

    span {
      text-align: center;
      font-size: 16px;
    }

    .prev,
    .next {
      display: none;
    }

    .team-cards {
      width: 100%;

      .card-wrapper {
        width: 100%;
        grid-template-columns: 320px;
        row-gap: 48px;
      }

      .card {
        width: 320px;
        column-gap: 32px;
      }
    }

    .actions {
      display: none;
    }
  }
`;
