import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  max-width: 1213px;
  height: 100vh;

  display: flex;
  align-items: center;

  .about-left {
    width: 50%;
  }

  .about-right {
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h1 {
      position: relative;
      font-size: 48px;
      color: var(--Brand-Title);
      font-family: 'Poppins', sans-serif;

      &::before {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 2px;
        background: var(--Brand-Red);

        position: absolute;
        top: 37px;
        left: 148px;
      }
    }

    p {
      font-size: 16px;
      line-height: 142%;
      font-weight: 500;
      font-family: 'montserrat', sans-serif;
      color: rgba(77, 77, 77, 0.5);
      margin-top: 8px;
      line-height: 160%;

      &:nth-child(3) {
        margin-top: 24px;
      }
    }
  }

  @media (max-width: 1920px) {
    max-height: 700px;
  }

  @media (max-width: 1100px) {
    height: auto;
    padding: 0 4px;

    flex-direction: column;
    flex-flow: column-reverse;

    .about-left {
      width: 100%;
      padding: 0 32px;
      margin-top: 32px;

      display: flex;
      justify-content: center;

      img {
        width: 100%;
        border-radius: 4px;
      }
    }

    .about-right {
      width: 100%;
      padding: 0 32px;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    max-height: fit-content;
  }

  @media (max-width: 425px) {
    .about-right {
      h1 {
        font-size: 32px;

        &::before {
          content: none;
        }
      }

      p {
        font-size: 14px;
        text-align: center;
      }
    }
  }
`;
