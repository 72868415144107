import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'aos/dist/aos.css';

export default createGlobalStyle`
  *{
    margin: 0;
    padding:0;
    outline:none;
    box-sizing: border-box;
  }

  :root {
    --Brand-Blue: #246FF2;
    --Brand-Yellow: #FFCC00;
    --Brand-Red: #E52619;
    --Brand-Title: #39393B;
    --Brand-Text: #CCCCCC;
    --Brand-Aux: #EDEDED;
    --Brand-White: #FFFFFF;
    
  }


  
@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}


  body, html {
    height: 100vh;
    position: relative;
    scroll-behavior: smooth;
  }

  input {
    font-size: 14px;
    font-family: 'poppins',sans-serif;
    background: var(--Brand-White);
  }

  textarea {
    resize: none;
  }
  
`;
