import React from 'react';

import { FiArrowUp } from 'react-icons/fi';

import Logo from '../../assets/Logo.svg';

import { Container } from './styles';

const Footer: React.FC = () => {
  const toTop = () => {
    document.documentElement.scrollTop = 0;
  };
  return (
    <Container>
      <div className="footer">
        <div>
          <div className="footer-left">
            <img src={Logo} alt="Tools Educacional" />
            <p>
              A Tools Educacional é um empresa especializada em transformar
              experiências de aprendizagem mediadas por Tecnologias.
            </p>
          </div>
          <div className="footer-right">
            <button onClick={toTop}>
              <FiArrowUp size={20} />
            </button>
          </div>
        </div>
      </div>
      <div className="copy">
        <div>
          <span className="copy-left">Tools Educacional 2021 ©</span>
          <span className="copy-right">
            Desenvolvido por: Tools Educacional Developer
          </span>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
