import styled, { keyframes } from 'styled-components';

const arrowAnimate = keyframes`
  0% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }

`;

export const Container = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  .footer {
    width: 100%;
    height: 164px;
    margin-top: 80px;
    padding: 0 32px;

    display: flex;
    justify-content: space-around;
    align-items: center;

    background: rgba(239, 242, 244, 0.94);

    div {
      width: 100%;
      max-width: 1213px;

      display: flex;

      .footer-left {
        width: 50%;
        display: flex;
        flex-direction: column;

        img {
          max-width: 30%;
        }

        p {
          font-size: 12px;
          font-family: 'montserrat', sans-serif;
          font-weight: 600;
          color: rgba(64, 57, 81, 0.63);
          margin-top: 8px;
          max-width: 310px;
        }

        @media (max-width: 425px) {
          width: 80%;

          img {
            max-width: 100%;
          }
        }

        @media (max-width: 320px) {
          width: 90%;

          img {
            max-width: 100%;
          }
        }
      }

      .footer-right {
        width: 50%;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: none;
          background: var(--Brand-Yellow);
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          transition: 0.4s;

          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            animation: ${arrowAnimate} 2s linear infinite;
          }

          &:hover {
            transform: translateY(-8px);
          }
        }
      }
    }
  }

  .copy {
    width: 100%;
    height: 48px;
    padding: 0 32px;
    background: var(--Brand-Blue);

    div {
      width: 100%;
      height: inherit;
      max-width: 1213px;
      margin: 0 auto;

      display: flex;

      .copy-left,
      .copy-right {
        width: 50%;
        font-size: 12px;
        color: var(--Brand-White);
        font-family: 'montserrat', sans-serif;

        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .copy-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    @media (max-width: 425px) {
      div {
        flex-direction: column;
        justify-content: center;

        .copy-left {
          width: 100%;
          justify-content: center;
        }

        .copy-right {
          width: 100%;
          justify-content: center;
          margin-top: 4px;
        }
      }
    }
  }
`;
