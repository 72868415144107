import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: rgb(255, 255, 255);

  display: flex;
  flex-direction: column;
  align-items: center;

  header {
    width: 100%;
    height: 80px;
    padding: 0 16px;
    max-width: 1213px;
    position: fixed;
    background: rgba(255, 255, 255, 1);
    z-index: 100;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .goBack {
      display: flex;
      align-items: center;

      a {
        margin-left: 4px;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        color: var(--Brand-Title);
        font-family: 'poppins', sans-serif;
      }

      svg {
        color: var(--Brand-Title);
      }
    }
  }

  .resources-container {
    width: 100%;
    max-width: 1213px;
    height: auto;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 42px;
      font-weight: bold;

      font-family: 'poppins', sans-serif;
      color: var(--Brand-Title);
      margin-top: 20%;
      line-height: 120%;
      position: relative;

      &::before {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 2px;
        background: var(--Brand-Red);

        position: absolute;
        top: 24px;
        right: -22px;
      }
    }

    .modules-container {
      width: 100%;
      margin-top: 80px;

      display: flex;
      flex-direction: column;

      .courses-container,
      .validate-container,
      .blog-container,
      .images-container {
        width: 100%;
        display: flex;
        align-items: center;

        .image-courses,
        .image-validate,
        .image-blog,
        .image-images {
          width: 50%;

          display: flex;
          justify-content: center;
        }

        .content {
          width: 50%;

          h2 {
            font-size: 32px;
            font-weight: bold;
            font-family: 'poppins', sans-serif;
            color: var(--Brand-Title);
            margin-bottom: 8px;
          }

          p {
            max-width: 520px;
            font-size: 18px;
            font-weight: 500;
            font-family: 'montserrat', sans-serif;
            color: var(--Brand-Title);
            line-height: 150%;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    position: relative;

    .resources-container {
      h1 {
        text-align: center;
        font-size: 42px;
        margin-top: 20%;
        max-width: 500px;

        &::before {
          content: none;
        }
      }
    }

    .modules-container {
      justify-content: center;

      .courses-container,
      .validate-container,
      .blog-container,
      .images-container {
        align-items: center;
        flex-direction: column;

        .image-courses,
        .image-validate,
        .image-blog,
        .image-images {
          width: 100% !important;

          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: inherit;
          }
        }

        .content {
          width: 100% !important;
          margin-bottom: 60px;
          padding: 0 32px;

          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          h2 {
            font-size: 32px;
            margin-bottom: 8px;
          }

          p {
            max-width: 650px !important;
          }
        }
      }

      .validate-container,
      .courses-container {
        flex-flow: column-reverse;
      }
    }
  }

  @media (max-width: 425px) {
    position: relative;

    .resources-container {
      h1 {
        text-align: center;
        font-size: 32px;
        margin-top: 40%;
        max-width: 500px;

        &::before {
          content: none;
        }
      }
    }

    .modules-container {
      justify-content: center;

      .courses-container,
      .validate-container,
      .blog-container,
      .images-container {
        align-items: center;
        flex-direction: column;

        .image-courses,
        .image-validate,
        .image-blog,
        .image-images {
          width: 100% !important;

          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: inherit;
          }
        }

        .content {
          width: 100% !important;
          margin-bottom: 60px;

          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          h2 {
            font-size: 32px;
            margin-bottom: 8px;
            text-align: center;
          }

          p {
            max-width: 320px !important;
            text-align: center;
          }
        }
      }

      .courses-container,
      .images-container {
        flex-flow: column-reverse;
      }
    }
  }
`;
